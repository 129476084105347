import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as AppActions from '../../AppActions';
const qs = require('query-string');

export default function NftDetailPage(props) {
  const params = qs.parse(props.location.search);
  const [nftInfo, setNftInfo] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        setNftInfo(
          await JStorage.fetchOneDocument('nft', {
            uid: params.uid,
          }),
        );
      } catch (ex) {
        Ant.message.warn('Not available');
        setNftInfo({
          pid: '0001',
          token_id: '---',
          asset_key: 'assets/df24b40c-37d8-48ba-8d7c-4e05f6fca17f',
          nftdata: {
            name: 'Not Available',
            image: '/images/no-nft-data.jpeg',
            description: 'Not Available',
            attributes: [],
          },
          status: 'nft_minted',
        });
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [params.uid]);

  if (!nftInfo) {
    return (
      <Wrapper>
        <div className="content">
          <h2>Loading...</h2>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <h2 style={{textAlign: 'center'}}>DIVER TAG #{nftInfo.token_id}</h2>
      <div className="content">
        <div className="nftdata">
          <h2 className="name">{nftInfo.nftdata?.name || '---'}</h2>
          <img src={nftInfo.nftdata?.image} alt="nft" />
          <div className="description">
            {nftInfo.nftdata?.description || '---'}
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 40px 0 80px;
  background-color: #eee;
  min-height: 100vh;

  & > .content {
    max-width: min(720px, 100% - 40px);
    margin-inline: auto;
    border-radius: 8px;
    padding: 12px;
    background-color: white;
    box-shadow: 0px 8px 12px rgba(0, 0, 0, 0.33);

    & img {
      width: 100%;
    }

    & .nftdata {
      & > .description {
        margin-top: 10px;
      }
    }
  }
`;
